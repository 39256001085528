<script lang="ts" setup>
const props = defineProps({
  linkTitle: String,
  icon: {
    type: String,
    default: 'icon-arrow-right3'
  },
  size: {
    type: String,
    default: ''
  }
})
</script>

<template>
  <nuxt-link class="link" :class="props.size">
    <div class="link__title" v-if="props.linkTitle">{{ props.linkTitle }}</div>
    <div class="link__icon">
      <svg-icon :name="props.icon" />
    </div>
  </nuxt-link>
</template>

<style lang="scss" scoped>
.link {
  display: flex;
  align-items: center;

  &__title {
    margin-right: toRem(10);
    color: $color-gray-1;
    font-weight: 700;
  }

  &__icon {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: toRem(40);
    height: toRem(40);
    border: toRem(1) solid $color-gray-line;
    font-size: toRem(18);
  }

  svg {
    width: toRem(16);
    height: toRem(16);
    color: $color-gray-1;
  }

  .brand3 {
    svg {
      width: toRem(16);
      height: toRem(16);
      color: $color-brand-3;
    }
  }

  &.small {
    .link {
      &__icon {
        width: toRem(28);
        height: toRem(28);
      }
    }
  }

  &.circle {
    .link {
      &__icon {
        border-radius: 50%;
      }
    }
  }
}
</style>
